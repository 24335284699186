import React, { useState } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom"; 
import * as api from "../../../actions/api";
import EntryofMCWCFormData from "./EntryofMCWCFormData";
import moment from "moment";


const EditEntryofMCWC = (props) => {

    //get DispensingLanguage
  const DispensingLanguage = JSON.parse(
    localStorage.getItem("DispensingLanguage")
  );
  const lan = localStorage.getItem("LangCode");
  const menukey = "entry-edit-of-mcwc";
  const UserInfo = sessionStorage.getItem("User_info")
  ? JSON.parse(sessionStorage.getItem("User_info"))
  : 0;  
  const UserName = UserInfo==0?'': UserInfo[0].id;


  const [selectedDate, setSelectedDate] = useState(
    new Date()
  );


  const [formData, setFormData] = useState({
    upazila_name:"",
    LandPhoneNo:"",
    up_address:"",
    UnionCode:"",
    UpazilaCode:"",
    SoftwareStartDate:"",
    IsChangeStartDate:""
  });

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["EntryofMCWC", id],
    () =>
      api.getEntryAndEidtMCWC(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

 
  const handleRDateChange = (date, field) => {
    let newDate;
   if(date==null || date==""){
     newDate="";
    }
    else{
      newDate = moment(date).format("YYYY-MM-DD");
    }
   
  setFormData({ ...formData, [field]: newDate });
  setErrorObject({ ...errorObject, [field]: null });
  api.dirtyModeEnable();
};

  const handleDateChange = (date) => {
    setSelectedDate(date);
    api.dirtyModeEnable();
  }; 

  const handleChange = (e) => {
    const { name, value } = e.target;
    let data = { ...formData };
    data[name] = value;

    setFormData(data);
    setErrorObject({ ...errorObject, [name]: null });
    api.dirtyModeEnable();
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;   
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
    api.dirtyModeEnable();
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
    api.dirtyModeEnable();
  };

  const handleReset = () => {
    setFormData({
      upazila_name:"",
      LandPhoneNo:"",
      up_address:"",
      UnionCode:"",
      UpazilaCode:"",
      SoftwareStartDate:"",
      IsChangeStartDate:""
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["upazila_name"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] =
          DispensingLanguage[lan][menukey]["field is Required !"];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };

  function checkValidateForm(){

     
    if (validateForm(formData)) {
    let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,}; 
   
    return fDtat;
    }else{
    return false;
    }

};
  // const handleUpdate = async (e) => {
  //   if (validateForm(formData)) {
  //     let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName}; 
  //     mutate(fDtat);
     
       
  //   }
  // };

  // const handleRDateChange = (date, field) => {
  
  //   setFormData({ ...formData, [field]: date });
  //   setErrorObject({ ...errorObject, [field]: null });
  //   api.dirtyModeEnable();
  // };

  return (
    <>
      <EntryofMCWCFormData
        errorObject={errorObject}
        addProductForm={false}
        formData={formData}
        handleChange={handleChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleCheck={handleCheck}
        handleReset={handleReset}
        checkValidateForm ={checkValidateForm}
        // handleUpdate={handleUpdate}
        handleRDateChange={handleRDateChange} 
        bStockUpdate={props.permissionType}
        {...props}
      />
    </>
  );
};

export default EditEntryofMCWC;
