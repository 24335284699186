import React, { useState ,useEffect } from "react";//, useEffect
import {
  Typography
} from "@material-ui/core";

import {
  useQuery,
  useMutation,
  useQueryClient,
} from 'react-query'
import { useParams } from 'react-router-dom';
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import * as api from '../../../actions/api';
import MasterManyFormData from './StandingBoardEntryItems.js';

import moment from "moment";

const AddSupplier = (props) => {

  //get DispensingLanguage
const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
const lan = localStorage.getItem('LangCode');
const menukey = "standing-board-entry";

const { t, i18n } = useTranslation();
const FacilityId=localStorage.getItem('FacilityId');

const CurrentFacilitySetting = JSON.parse(localStorage.getItem('CurrentFacilitySetting'));
const CurrFacilitySetting = CurrentFacilitySetting[0];

const [selectedDate, setSelectedDate] = useState(
  //new Date()
  moment().format("YYYY-MM-DD")
);
const [generatedInvoiceNo, setGeneratedInvoiceNo] = useState(
  ''
); 
  const [formData, setFormData] = useState({
      TransactionDate: selectedDate,
      YearId: "",
      PackageName: "",
      ContractName: "",
  });

const [ManyTableData, setManyTableData] = useState( []); 
const [ManyTableMergeData, setManyTableMergeData] = useState( []); 
const [updatedDetailGridData, setUpdatedDetailGridData] = useState([]);

const handleRDateChange = (date, field) => {

   let newDate;
 if(date==null){
  newDate=date;
 }else{
   newDate = moment(date).format("YYYY-MM-DD");
 }
  setFormData({ ...formData, [field]: newDate });
  setErrorObject({ ...errorObject, [field]: null });
};

 const handleDateChange = (date) => {
  setSelectedDate(date);
}; 
 
  const [errorObject, setErrorObject] = useState({});
   
  const { id } = useParams();
  const queryClient = useQueryClient();

  // let params={"menukey": menukey, "FacilityId":FacilityId, "TransactionTypeId":1, TransactionDate: '', TableName: 'provisionalrecinv'};
  // const {  data:invoiceNo, refetch } = useQuery([params], api.getInvoiceNo, 
  //   {onSuccess: (data) => {
      
  //     setGeneratedInvoiceNo(data);
  //     let fdata = { ...formData }; 
  //     fdata["TransactionNo"] = data; 
  //     setFormData(fdata); 

  //   }}
  //   );

  const { isLoading, mutate } = useMutation(api.StandingBoardEntryDataInsert, {
    onSuccess: (data) => { 
      if (data.status == 200) {
        console.log('data: ', data);
        console.log('data.TransactionId: ', data.TransactionId);
        // success
      // props.openNoticeModal({
      //   isOpen: true,
      //   msg: data.message,
      //   msgtype: data.success,
      // });

      mutatePost(data.TransactionId);

      // let route = `edit/${data.TransactionId}`;
      // props.history.push(route); 

      // let route = "provisional-receive";
      // props.history.push(route); 
      
      }else{
        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
      }
    }
  })  




  const { isLoading1, mutate:mutatePost } = useMutation(api.postReceiveFromSupplier, {
    onSuccess: (data) => { 
      if (data.status == 200) {
        //let route = "provisional-receive";
        let route = "receive-from-supplier";
        props.history.push(route);  

        if(data.data.success==0){ 

          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
         
         }else{
         
          props.openNoticeModal({
            isOpen: true,
            msg: data.data.message,
            msgtype: data.data.success,
          });
  
         } 

      }else{

        props.openNoticeModal({
          isOpen: true,
          msg: data.data.message,
          msgtype: data.data.success,
        });
      }
    }
  })







  const handleAdd = (NonOrderProductData) => {
      setManyTableData(NonOrderProductData);
      setUpdatedDetailGridData(NonOrderProductData);
  };

  const handleAddManyMerge = (NonOrderProductData) => {

     let newMergeArray = ManyTableData.filter(element => element !== NonOrderProductData);
     //console.log("newMergeArray=======", newMergeArray);
     // const tmpNonOrderProductData = [...NonOrderProductData, ...NonOrderProductData];
     setManyTableMergeData(newMergeArray);

  };
 
  const handleAddContract = (ContractData) => {

    let fdata = { ...formData }; 
    let row = ContractData;

    fdata["ContractName"] = row[0].ContractName;
    fdata["ContractNo"] = row[0].ContractNo;
    fdata["PackageName"] = row[0].PackageName;
    fdata["PackageNo"] = row[0].PackageNo;
    fdata["PackageYear"] = row[0].PackageYear;
    fdata["YearId"] = row[0].YearId;

    setFormData(fdata); 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log('formData: ', e.target);

      let data = { ...formData };
      
      data[name] = value; 
      setFormData(data); 
    setErrorObject({ ...errorObject, [name]: null });
  };

  //====== Start Choosen Dropdown===========
  const handleChangeChoosenProps = (name, value) => {
    let data = { ...formData };
    data[name] = value;   
    setFormData(data); 
    setErrorObject({ ...errorObject, [name]: "" });
  }
  //====== End Choosen Dropdown===========

  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };


  const invoiceCancel = (e)=>{

    // // console.log('handleCheck e: ', e);
    // const { name, checked } = e.target;
    // // console.log('name, checked: ', name, checked);
    // // console.log('invoiceCancel formData: ', formData);

    // swal({
    //   title: DispensingLanguage[lan][menukey]["Are you sure?"],
    //   text: DispensingLanguage[lan][menukey]["Do you really want to cancel?"]+" "+DispensingLanguage[lan][menukey]["You will not able to edit this invoice after cancel"],
    //   icon: "warning",
    //   buttons: [DispensingLanguage[lan][menukey]["No"], 
    //   DispensingLanguage[lan][menukey]["Yes"]],
    //   dangerMode: true,
    // }).then((willCancel) => {
    //   if (willCancel) {
    //     let fDtat={...formData,'ManyJsonSave':ManyTableData,"BCancel":1,"menukey":"receive"};
    //     mutate(fDtat);
    //   }else{
    //     setFormData({ ...formData, [name]: false });
    //   }
    // });

  };

  const handleReset = () => {
    setFormData({
      TransactionDate: "",
      YearId: "",
      PackageName: "",
      ContractName: "",

    });
  };

  const validateForm = (formData) => {
    let validateFields = ["TransactionDate", "YearId", "ContractName"]
    let errorData = {}
    let isValid = true
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !']
        isValid = false
      }
    })
    setErrorObject(errorData);
    return isValid
  }

  const handleSubmit = async (e) => {

    if (validateForm(formData)) {
      let fDtat={
        ...formData, 
        ManyJsonSave: ManyTableData,
        menukey: menukey,
      };
      mutate(fDtat);
    }
  };
  const deleteReceiveInv=(data)=>{
    
    let newarray = ManyTableData.filter(element => element !== data); 
    setManyTableData(newarray);
  }

  const setUpdatedDetailsGridDataHandler = (rows, disapprovedItemsRows) => { 
    //console.log("Approved..................",rows);
    //console.log("Disapproved..................",disapprovedItemsRows);



    if (validateForm(formData)) {

      swal({
        title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
        text: t(DispensingLanguage[lan][menukey]["Do you really want to generate received invoice?"]),
        icon: "warning",
        buttons: {
          confirm: {
            text: t(DispensingLanguage[lan][menukey]["Yes"]),
            value: true,
            visible: true,
            className: "",
            closeModal: true,
          },
          cancel: {
            text: t(DispensingLanguage[lan][menukey]["No"]),
            value: null,
            visible: true,
            className: "",
            closeModal: true,
          },
        },
        /* buttons: [t(DispensingLanguage[lan][menukey]["No"]), 
        t(DispensingLanguage[lan][menukey]["Yes"])],
        dangerMode: true, */
      }).then((willCancel) => {
        if (willCancel) {
          let fDtat={...formData,'ManyJsonSave':rows, 'DisapprovedManyJsonSave':disapprovedItemsRows, "menukey": menukey}; 
          mutate(fDtat); 
        }
      });


      


      }

    setManyTableData(rows); 





  };

  useEffect(() => { 

  }, []); 

  return (
    <> 
      <MasterManyFormData 
      errorObject={errorObject} 
      addProductForm={true} 
      formData={formData} 
      handleChange={handleChange} 
      handleChangeChoosenProps={handleChangeChoosenProps} 
      handleCheck={handleCheck} 
      handleReset={handleReset} 
      invoiceCancel={invoiceCancel} 
      handleSubmit={handleSubmit} 
      ManyTableData={updatedDetailGridData}   
      ManyTableMergeData={ManyTableMergeData}   
      handleRDateChange={handleRDateChange} 
      selectedDate={selectedDate} 
      deleteReceiveInv={deleteReceiveInv}
      setUpdatedDetailsGridDataHandler={setUpdatedDetailsGridDataHandler}
      bStockUpdate={props.permissionType}
      handleAdd={handleAdd} 
      handleAddManyMerge={handleAddManyMerge} 
      generatedInvoiceNo={generatedInvoiceNo}
      handleAddContract={handleAddContract}

      {...props} />
      
    </>
  );
};

export default AddSupplier;