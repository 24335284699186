import React, { Suspense, useState, useLayoutEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss?v=1.4.0";
import "assets/demo/demo.css?v=1.4.0";
import "assets/demo/nucleo-icons-page-styles.css?v=1.4.0";
import "assets/css/custom.css";

import DarkFooter from "components/Footers/DarkFooter";

// pages for this kit
import PhysicalInventory from "views/screens/physicalinventory/index.js";
import Index from "views/Index.js";
import LoginPage from "views/screens/LoginPage.js";
//import SignUpPage from "views/screens/SignUp.js";
import ResetPassword from "views/screens/ResetPasswordPage";
import DashboardPage from "views/screens/DashboardPage";
import MCWCDashboardPage from "views/screens/MCWCDashboardPage";
// import PatientDashboard from "views/screens/PatientDashboard";
// import LogisticsDashboard from "views/screens/LogisticsDashboard";
import Users from "views/screens/user/index.js";
import Admin from "views/screens/admin/index.js";
import Products from "views/screens/products/index.js";
import Reports from "views/screens/report/index.js";
import StockStatus from "views/screens/stock_status/index.js";
import LocalPurchase from "views/screens/local_purchase/index.js";
import LocalPurchaseFacility from "views/screens/local_purchase_facility/index.js";
import PhysicalInventoryEntry from "views/screens/physical_inventory_entry/index.js";
import Receive from "views/screens/receive/index.js";
import ReceiveFacility from "views/screens/receivefacility/index.js";
import Entryofproduct from "views/screens/entryofproduct/index.js";


import ProductGroup from "views/screens/product_group/index.js";
import ProductSpecification from "views/screens/product_specification/index.js";
import Year from "views/screens/year/index.js";
import Indentor from "views/screens/indentor/index.js";
import ProductSpecificationRpt from "views/screens/product_specification_rpt/index.js";
import CatalogUserMap from "views/screens/catalogusermap/index.js";
import AuditlogProductCatalog from "views/screens/audit-log-product-catalog/index.js";

import ReceiveFromSupplier from "views/screens/receive_from_supplier/index.js";
import IssueVoucherPush from "views/screens/issuevoucherpush/index.js";
import MCWCIssueVoucherAsset from "views/screens/mcwcassetissuefromstore/index.js";
import IssueVoucherIndent from "views/screens/issuevoucherindent/index.js";
import Adjustment from "views/screens/adjustment/index.js";
import AdjustmentFacility from "views/screens/adjustment_facility/index.js";
import AdjustmentApproval from "views/screens/adjustment_approval/index.js";
import AdjustmentFacilityApproval from "views/screens/adjustment_facility_approval/index.js";
import AdjustmentType from "views/screens/adjustment_type/index.js";
import Form7 from "views/screens/form7/index.js";
import Form7B from "views/screens/form7b/index.js";
import Form7Buims from "views/screens/form7buims/index.js";
import LMISForm2 from "views/screens/lmisform2/index.js";
import LMISForm3 from "views/screens/lmisform3/index.js";
import MCWCMonthlyForm3 from "views/screens/mcwcmonthlyform3/index.js";

import SupplyPlan from "views/screens/supplyplan/index.js";
import AssetRegister from "views/screens/asset_register/index.js";
import UpazilaSupplyPlan from "views/screens/upazilasupplyplan/index.js";
import StockExpiryByLot from "views/screens/sockExpiryByLot/index.js";
import Uom from "views/screens/uom/index.js";
import GatePass from "views/screens/gate_pass/index.js";
import Indent from "views/screens/indent/index.js";
import Errorlog from "views/screens/error-log/index.js";
import Assetstatus from "views/screens/assetstatus/index.js";
import ProcuringEntity from "views/screens/procuring_entity/index.js";
import Fundingsource from "views/screens/fundingsource/index.js";
import Auditlog from "views/screens/audit-log/index.js";
import OptionsForm from "views/screens/options_form/index.js";
import OthersFacilityList from "views/screens/others_facility_list/index.js";
import EntryeditofContractItems from "views/screens/entryeditofContractItems/index.js";
import Role from "views/screens/roleentryfrom/index.js";
import Menuentry from "views/screens/menuentry/index.js";
import Roleaccessentry from "views/screens/roleaccessentry/index.js";
import UiLanguage from "views/screens/ui_language/index.js";
import Facility from "views/screens/facility/index.js";
import UserEntry from "views/screens/user_entry/index.js";
import Employee from "views/screens/employee/index.js";
import StockSummarys from "views/screens/stock_summary/index.js";
import StockSummarybylot from "views/screens/stockSummarybylot/index.js";
import IssueDetailsByFacilityReport from "views/screens/issue_details_by_facility/index.js";
import WarehouseIssueDetailsByFacilityReport from "views/screens/warehouse_issue_details_by_facility/index.js";
import IssueDetailsByItemReport from "views/screens/issue_details_by_item/index.js";
import DispenseDetailsByItem from "views/screens/dispense_details_by_item/index.js";
import MCWCDispenseDetailsByItem from "views/screens/mcwc_dispense_details_by_item/index.js";
import DispenseSummary from "views/screens/dispense_summary/index.js";
import MCWCDispenseSummary from "views/screens/mcwc_dispense_summary/index.js";
import ReceiveReport from "views/screens/receive_report/index.js";
import AdjustmentsReport from "views/screens/adjustments_report/index.js";
import DigitalICR from "views/screens/digital_icr/index.js";
import StockTransactionsByLot from "views/screens/stock_transactions_by_lot/index.js";
// import ConsumptionTrendsForTheTopFiveProducts from "views/screens/ConsumptionTrendsForTheTopFiveProducts";
// import StockStatusDetails from "views/screens/StockStatusDetails";
// import AMCTrendsforTheTopFiveProducts from "views/screens/AMCTrendsforTheTopFiveProducts";
// import PercentageOfFacilitiesWithAStockoutOfTracerCommodities from "views/screens/PercentageOfFacilitiesWithAStockoutOfTracerCommodities";
// import AvailabilityOfCommodities from "views/screens/AvailabilityOfCommodities";
import MyProfile from "views/screens/myprofile/index.js";
// import AdultPatientsByProtocol from "views/screens/AdultPatientsByProtocol";
// import PaediatricPatientsByProtocol from "views/screens/PaediatricPatientsByProtocol";
// import TrendofActivePatients from "views/screens/TrendofActivePatients";
// import NumberofActivePatients from "views/screens/NumberofActivePatients";
// import TldUptake from "views/screens/TldUptake";
// import TldTransitionTrend from "views/screens/TldTransitionTrend";
// import MmdAmongAdults from "views/screens/MmdAmongAdults";
// import MmdCoverageBysite from "views/screens/MmdCoverageBysite";
import Nationalsupplie from "views/screens/nationalsupplier/index.js";
import SlowMovingItemReport from "views/screens/Slow_Moving_Item_Report/index.js";
import FormItemSerial from "views/screens/Form_Item_Serial/index.js";
import WarehouseFormItemSerial from "views/screens/Warehouse_Form_Item_Serial/index.js";
import WarehouseSupplyPlanItemsSerial from "views/screens/Warehouse_Supply_Plan_Items_Serial/index.js";
import UpazilaSupplyPlanItemsSerial from "views/screens/Upazila_Supply_Plan_Items_Serial/index.js";
import EditEntryandEditofPackage from "views/screens/entry_and_edit_of_package/index.js";
import ProvisionalReceive from "views/screens/provisional_receive/index.js";
import ReturnTotheSupplierFromProvisionalReceive from "views/screens/Return_to_the_Supplier_from_Provisional_Receive/index.js";
import StandingBoardEntry from "views/screens/standing_board_entry/index.js";
import UnionEntry from "views/screens/unionEntry/index.js";
import DDFPInformationEntry from "views/screens/ddfpInformationEntry/index.js";
import StoreType from "views/screens/storetype/index.js";
import UnitEntry from "views/screens/unitentry/index.js";
import EntryofUpazila from "views/screens/entryofUpazila/index.js";
import EntryofMCWC from "views/screens/entryofMCWC/index.js";
import EntryofWarehouse from "views/screens/entryofWarehouse/index.js";
import EntryofSupplier from "views/screens/entryofsupplier/index.js";
import CustomSupplyPlanEntry from "views/screens/customsupplyplan/index.js";
import UpazilaCustomSupplyPlanEntry from "views/screens/upazilacustomsupplyplan/index.js";
import ProvisionalReceivingRegister from "views/screens/provisional_receiving_register/index.js";
import ProvisionalSummaryReport from "views/screens/provisional_summary_report/index.js";
import HealthFacilityEntry from "views/screens/health_facility_entry/index.js";
// import TldStockstatus from "views/screens/TldStockstatus";
import UserContextProvider from './context/user-info-context';
import SDPStockOutReport from "views/screens/sdp_stockout_report/index.js";
import From23SubmissionReport from "views/screens/from_2-3_submission_report/index.js";
import SDPDistributionReport from "views/screens/sdp_distribution_report/index.js";

import AssetDepartment from "views/screens/asset_department/index.js";
import AssetMaintenanceSchedule from "views/screens/asset_maintenance_schedule/index.js";
import AssetUnit from "views/screens/asset_unit/index.js";
import DDSkitItemSerial from "views/screens/ddskit_item_serial_list/index.js";
import CountryEntry from "views/screens/country_entry/index.js";

import SDPLedgerReport from "views/screens/sdp_ledger_report/index.js";
import SDPDistributionSummary from "views/screens/sdp_distribution_summary/index.js";
import IssueVoucher from "views/screens/issue_voucher/index.js";
import SDPClosingSummary from "views/screens/sdp_closing_summary/index.js";
import SDPActiveInactiveReport from "views/screens/sdp_activeinactive_report/index.js";

import StockSummarysGlobal from "views/screens/stock_summary_global/index.js";
import StockSummaryNational from "views/screens/stock_summary_national/index.js";
import StockSummarybyLotGlobal from "views/screens/stockSummarybylotGlobal/index.js";
import DigitalICRGlobal from "views/screens/digital_icr _global/index.js";
import MCWCDigitalICRGlobal from "views/screens/mcwc_digital_icr _global/index.js";
import ReceiveReportGlobal from "views/screens/receive_report_global/index.js";
import MCWCReceiveReport from "views/screens/mcwc_receiver_report/index.js";
import IssueDetailsByItemGlobal from "views/screens/issue_details_by_item _Global/index.js";
import IssueDetailsByFacilityGlobal from "views/screens/issue_details_by_facility_global/index.js";
import StockExpiryByLotGlobal from "views/screens/stock_expiry_by_lot_global/index.js";
import StockExpiryByLotMCWC from "views/screens/mcwc_stock_expiry_by_lot/index.js";
import AdjustmentsReportGlobal from "views/screens/adjustments_report_global/index.js";
import AdjustmentsReportmcwc from "views/screens/adjustments_report_mcwc/index.js";
import StockTransactionsByLotGlobal from "views/screens/stock_transactions_by_lot_global/index.js";
import WarehouseIssueDetailsByFacilityGlobal from "views/screens/warehouse_issue_details_by_facility_global/index.js";
import Form7bGlobal from "views/screens/form7b_global/index.js";
import Form7Global from "views/screens/form7_view_global/index.js";
import ProvisionalReceivingRegisterGlobal from "views/screens/provisional_receiving_register_global/index.js";
import ProvisionalSummaryReportGlobal from "views/screens/provisional_summary_report_global/index.js";

import MCWCStockSummary from "views/screens/mcwc-stock-summary/index.js";
import MCWCStockSummarybyLot from "views/screens/mcwc-stock-summarybylot/index.js";


import SDPDistributionSummaryGlobal from "views/screens/sdp_distribution_summary_global/index.js";
import AssetView from "views/screens/asset_report/index.js";
import AssetViewGlobal from "views/screens/mcwc_asset_report_global/index.js";
import AssetReport from "views/screens/asset_m_report/index.js";
import From23SubmissionReportGlobal from "views/screens/from_2-3_submission_report_global/index.js";
import SDPStockOutReportGlobal from "views/screens/sdp_stockout_report_global/index.js";
import SDPLedgerReportGlobal from "views/screens/sdp_ledger_report_global/index.js";
import SDPClosingSummaryGlobal from "views/screens/sdp_closing_summary_global/index.js";
import SDPDistributionReportGlobal from "views/screens/sdp_distribution_report_global/index.js";

import DispenseFacility from "views/screens/dispense_facility/index.js";

import FacilityeLMISActivityStatus from "views/screens/facility_elmis_activity_status/index.js";

import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

import SdpList from "views/screens/sdpList/index.js";
import UpazilaList from "views/screens/upazila_list/index.js";
// import * as Service from "./services/Service.js";
import CheckPermission from "views/screens/CheckPermission.js";
import LoadingSpinner from "./services/LoadingSpinner";

import packageJson from "../package.json";
import swal from "sweetalert";

const queryClient = new QueryClient();

let userInfo = null;

userInfo = {
  FacilityId: 0,
  FacilityName: 'CHUZ-SURU-LERE',
  LangId: 'fr_FR'
};



const clearCacheData = () => {
  caches.keys().then((names) => {
      names.forEach((name) => {
          caches.delete(name);
      });
  });
   
  setTimeout(function(){
    window.location.reload();
 }, 1000);


};


if(localStorage.getItem("Version")==null){
 

  swal({
    title: "",
    text:  'A new version is available',
    icon: "warning",
    button: 'Refresh',
    showCancelButton: false,
    showConfirmButton: false
    }).then((willDelete) => {
    if (willDelete) { 
      localStorage.setItem(
        "Version",
        packageJson.version
      );
      window.location.href = `${process.env.REACT_APP_BASE_NAME}`;
      clearCacheData(); 
      
    }
  });



 }else if(localStorage.getItem("Version")!=packageJson.version){

  

  swal({
    title: "",
    text:  'A new version is available',
    icon: "warning",
    button: 'Refresh',
    showCancelButton: false,
    showConfirmButton: false
    }).then((willDelete) => {
    if (willDelete) { 
      localStorage.setItem(
        "Version",
        packageJson.version
      );
      window.location.href = `${process.env.REACT_APP_BASE_NAME}`;
      clearCacheData(); 
    }
  });

 } 








const MyApp = () => {
  
  const [Dispensing_Language, setDispensing_Language] = useState(false);

  //const [DispensingLanguage, setDispensingLanguage] = useState({});

  useLayoutEffect(() => {

    /*if (!Dispensing_Language) {
      Service.default
        .postApi("source/combo_generic.php", {
          action: "getDispensingLanguageList",
        })
        .then((res) => {
          if (res.success == 1) {
            let DispensingLanguageData = res.datalist;

            localStorage.setItem(
              "DispensingLanguage",
              JSON.stringify(DispensingLanguageData)
            );

            setDispensingLanguage(
              JSON.parse(localStorage.getItem("DispensingLanguage"))
            );

            setDispensing_Language(true);
          }
        })
        .catch((err) => {});
    }*/

     setDispensing_Language(true);

  }, []);
  
  return (
    <>
      {!Dispensing_Language && <LoadingSpinner />}
      {/* {Dispensing_Language && Object.keys(DispensingLanguage).length !== 0 && ( */}
        <UserContextProvider userInfo={userInfo}>
        <QueryClientProvider client={queryClient}>
          <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
            <Suspense>
              <Switch>
                <Route path="/home" render={(props) => <Index {...props} />} />
                <Route path="/login" render={(props) => <LoginPage {...props} />} />
                {/* <Route path="/signup" render={(props) => <SignUpPage {...props} />} /> */}
                <Route
                  path="/reset-password"
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  path="/dashboard"
                  render={(props) => <DashboardPage {...props} />}
                />
                <Route
                  path="/mcwc-dashboard"
                  render={(props) => <MCWCDashboardPage {...props} />}
                />
                {/* <Route
                  path="/patient-dashboard"
                  render={(props) => <PatientDashboard {...props} />}
                /> */}
                {/* <Route path="/logistics-dashboard" render={(props) => <LogisticsDashboard {...props} />} /> */}
                <Route path="/users" render={(props) => <Users {...props} />} />
                <Route path="/admin" render={(props) => <Admin {...props} />} />
                <Route path="/products" render={(props) => <Products {...props} />} />
                <Route path="/entry-edit-of-products" render={(props) => <Entryofproduct {...props} />} />

                <Route path="/product-group" render={(props) => <ProductGroup {...props} />} />
                <Route path="/product-specification" render={(props) => <ProductSpecification {...props} />} />
                <Route path="/year" render={(props) => <Year {...props} />} />
                <Route path="/indentor" render={(props) => <Indentor {...props} />} />
                <Route path="/product-specification-report" render={(props) => <ProductSpecificationRpt {...props} />} />
                <Route path="/catalog-user-map" render={(props) => <CatalogUserMap {...props} />} />
                <Route path="/audit-log-product-catalog" render={(props) => <AuditlogProductCatalog {...props} />} />

                <Route path="/entry-edit-of-contract-items" render={(props) => <EntryeditofContractItems {...props} />} />
                <Route path="/report" render={(props) => <Reports {...props} />} />
                <Route path="/stock-status" render={(props) => <StockStatus {...props} />} />
                <Route path="/local-purchase" render={(props) => <LocalPurchase {...props} />} />
                <Route path="/local-purchase-facility" render={(props) => <LocalPurchaseFacility {...props} />} />
                <Route path="/physical-inventory-entry" render={(props) => <PhysicalInventoryEntry {...props} />} />
                <Route path="/receive" render={(props) => <Receive {...props} />} />
                <Route path="/receive-facility" render={(props) => <ReceiveFacility {...props} />} />
                <Route path="/receive-from-supplier" render={(props) => <ReceiveFromSupplier {...props} />} />
                <Route path="/issuevoucherpush" render={(props) => <IssueVoucherPush {...props} />} />
                <Route path="/mcwcassetissuefromstore" render={(props) => <MCWCIssueVoucherAsset {...props} />} />
                <Route path="/issuevoucherindent" render={(props) => <IssueVoucherIndent {...props} />} />
                <Route path="/adjustment" render={(props) => <Adjustment {...props} />} />
                <Route path="/adjustment-facility" render={(props) => <AdjustmentFacility {...props} />} />
                <Route path="/adjustment-approval" render={(props) => <AdjustmentApproval {...props} />} />
                <Route path="/adjustment-facility-approval" render={(props) => <AdjustmentFacilityApproval {...props} />} />
                <Route path="/adjustment-type" render={(props) => <AdjustmentType {...props} />} />
                <Route path="/dispense-facility" render={(props) => <DispenseFacility {...props} />} />
                
                <Route path="/form7" render={(props) => <Form7 {...props} />} />
                <Route path="/form7b" render={(props) => <Form7B {...props} />} />
                <Route path="/form7buims" render={(props) => <Form7Buims {...props} />} />
                <Route path="/lmisform2" render={(props) => <LMISForm2 {...props} />} />
                <Route path="/lmisform3" render={(props) => <LMISForm3 {...props} />} />

                <Route path="/mcwc-monthly-form3" render={(props) => <MCWCMonthlyForm3 {...props} />} />

                <Route path="/supplyplan" render={(props) => <SupplyPlan {...props} />} />
                <Route path="/asset-register" render={(props) => <AssetRegister {...props} />} />
                <Route path="/upazilasupplyplan" render={(props) => <UpazilaSupplyPlan {...props} />} />
                
                <Route path="/provisional-receive" render={(props) => <ProvisionalReceive {...props} />} />
                <Route path="/return-to-the-supplier-from-provisional-receive" render={(props) => <ReturnTotheSupplierFromProvisionalReceive {...props} />} />
                <Route path="/unit-of-measure-entry-form" render={(props) => <Uom {...props} />} />
                <Route path="/physical-inventory" render={(props) => <PhysicalInventory {...props} />} />
                <Route path="/stock-expiry-by-lot" render={(props) => <StockExpiryByLot {...props} />} />
                <Route path="/role-entry" render={(props) => <Role {...props} />} />
                <Route path="/menu-entry" render={(props) => <Menuentry {...props} />} />
                <Route path="/role-access-entry" render={(props) => <Roleaccessentry {...props} />} />
                <Route path="/ui-language" render={(props) => <UiLanguage {...props} />} />
                <Route path="/facility" render={(props) => <Facility {...props} />} />
                <Route path="/user-entry" render={(props) => <UserEntry {...props} />} />
                <Route path="/employee-entry" render={(props) => <Employee {...props} />} />
              
                <Route path="/stock-summary" render={(props) => <StockSummarys {...props} />} />
                <Route path="/stockSummaryby-lot" render={(props) => <StockSummarybylot {...props} />} />
                <Route path="/issue-details-by-facility" render={(props) => <IssueDetailsByFacilityReport {...props} />} />
                <Route path="/warehouse-issue-details-by-facility" render={(props) => <WarehouseIssueDetailsByFacilityReport {...props} />} />
                <Route path="/issue-details-by-item" render={(props) => <IssueDetailsByItemReport {...props} />} />
                <Route path="/dispense-details-by-item" render={(props) => <DispenseDetailsByItem {...props} />} />
                <Route path="/mcwc-dispense-details-by-item" render={(props) => <MCWCDispenseDetailsByItem {...props} />} />
                
                <Route path="/dispense-summary" render={(props) => <DispenseSummary {...props} />} />
                <Route path="/mcwc-dispense-summary" render={(props) => <MCWCDispenseSummary {...props} />} />
                <Route path="/receive-report" render={(props) => <ReceiveReport {...props} />} />
                <Route path="/adjustments-report" render={(props) => <AdjustmentsReport {...props} />} />
                <Route path="/digital-icr" render={(props) => <DigitalICR {...props} />} />
                <Route path="/stock-transactions-by-lot" render={(props) => <StockTransactionsByLot {...props} />} />
                {/* <Route path="/consumption-trends-for-the-top-five-products" render={(props) => <ConsumptionTrendsForTheTopFiveProducts {...props} />} /> */}
                {/* <Route path="/stock-status-details" render={(props) => <StockStatusDetails {...props} />} /> */}
                {/* <Route path="/amc-trends-for-the-top-five-products" render={(props) => <AMCTrendsforTheTopFiveProducts {...props} />} /> */}
                {/* <Route path="/percentage-of-facilities-with-a-stockout-of-tracer-commodities" render={(props) => <PercentageOfFacilitiesWithAStockoutOfTracerCommodities {...props} />} /> */}
                {/* <Route path="/availability-of-commodities" render={(props) => <AvailabilityOfCommodities {...props} />} /> */}

                <Route path="/my-profile" render={(props) => <MyProfile {...props} />} />
                <Route path="/sptList-entry" render={(props) => <SdpList {...props} />} />
                {/* <Route path="/adult-patients-by-protocol" render={(props) => <AdultPatientsByProtocol {...props} />} />
                <Route path="/paediatric-patients-by-protocol" render={(props) => <PaediatricPatientsByProtocol {...props} />} />
                <Route path="/trend-of-active-patients" render={(props) => <TrendofActivePatients {...props} />} />
                <Route path="/number-of-active-patients" render={(props) => <NumberofActivePatients {...props} />} /> */}
                {/* <Route path="/tld-uptake" render={(props) => <TldUptake {...props} />} /> */}
                {/* <Route path="/tld-transition-trend" render={(props) => <TldTransitionTrend {...props} />} /> */}
                {/* <Route path="/mmd-among-adults" render={(props) => <MmdAmongAdults {...props} />} /> */}
                {/* <Route path="/mmd-coverage-by-site" render={(props) => <MmdCoverageBysite {...props} />} /> */}
                <Route path="/national-supplier" render={(props) => <Nationalsupplie {...props} />} />
                {/* <Route path="/tld-stock-status" render={(props) => <TldStockstatus {...props} />} /> */}
                <Route path="/gate-pass" render={(props) => <GatePass {...props} />} />
                <Route path="/indent" render={(props) => <Indent {...props} />} />
                <Route path="/error-log" render={(props) => <Errorlog {...props} />} />
                <Route path="/asset-status-entry" render={(props) => <Assetstatus {...props} />} />
                <Route path="/asset-procuring" render={(props) => <ProcuringEntity {...props} />} />
                <Route path="/funding-source-entry" render={(props) => <Fundingsource {...props} />} />
                <Route path="/audit-log" render={(props) => <Auditlog {...props} />} />
                <Route path="/options" render={(props) => <OptionsForm {...props} />} />
                <Route path="/others-facility-list" render={(props) => <OthersFacilityList {...props} />} />
                <Route path="/standing-board-entry" render={(props) => <StandingBoardEntry {...props} />} />
                <Route path="/customsupplyplan" render={(props) => <CustomSupplyPlanEntry {...props} />} />
                <Route path="/upazilacustomsupplyplan" render={(props) => <UpazilaCustomSupplyPlanEntry {...props} />} />
                <Route path="/union-entry-form" render={(props) => <UnionEntry {...props} />} />
                <Route path="/ddfp-information-entry" render={(props) => <DDFPInformationEntry {...props} />} />
                <Route path="/unit-entry" render={(props) => <UnitEntry {...props} />} />
                <Route path="/entry-edit-of-upazila" render={(props) => <EntryofUpazila {...props} />} />
                <Route path="/entry-edit-of-mcwc" render={(props) => <EntryofMCWC {...props} />} />
                <Route path="/entry-edit-of-warehouse" render={(props) => <EntryofWarehouse {...props} />} />
                <Route path="/store-type-entry" render={(props) => <StoreType {...props} />} />
                <Route path="/entry-edit-of-supplier" render={(props) => <EntryofSupplier {...props} />} />
                <Route path="/provisional-receiving-register" render={(props) => <ProvisionalReceivingRegister {...props} />} />
                <Route path="/provisional-summary-report" render={(props) => <ProvisionalSummaryReport {...props} />} />
                <Route path="/health-facility-entry" render={(props) => <HealthFacilityEntry {...props} />} />
                <Route path="/slow-moving-item-list-report" render={(props) => <SlowMovingItemReport {...props} />} />
                <Route path="/upazila-form-item-serial" render={(props) => <FormItemSerial {...props} />} />
                <Route path="/warehouse-form-item-serial" render={(props) => <WarehouseFormItemSerial {...props} />} />
                <Route path="/warehouse-supply-plan-items-serial" render={(props) => <WarehouseSupplyPlanItemsSerial {...props} />} />
                <Route path="/upazila-supply-plan-items-serial" render={(props) => <UpazilaSupplyPlanItemsSerial {...props} />} />
                <Route path="/entry-and-edit-of-package" render={(props) => <EditEntryandEditofPackage {...props} />} />
                <Route path="/sdp_stockout_report" render={(props) => <SDPStockOutReport {...props} />} />
                <Route path="/from_2-3_submission_report" render={(props) => <From23SubmissionReport {...props} />} />
                <Route path="/sdp_distribution_report" render={(props) => <SDPDistributionReport {...props} />} />

                <Route path="/sdp_ledger_report" render={(props) => <SDPLedgerReport {...props} />} />
                <Route path="/issuevoucher" render={(props) => <IssueVoucher {...props} />} />
                <Route path="/sdp_distribution_summary" render={(props) => <SDPDistributionSummary {...props} />} />
                <Route path="/sdp_closing_summary" render={(props) => <SDPClosingSummary {...props} />} />
                <Route path="/sdp_activeinactive_report" render={(props) => <SDPActiveInactiveReport {...props} />} />
                <Route path="/upazila_list" render={(props) => <UpazilaList {...props} />} />

                <Route path="/stock-summary-global" render={(props) => <StockSummarysGlobal {...props} />} />
                <Route path="/stock-summary-national" render={(props) => <StockSummaryNational {...props} />} />
                
                <Route path="/stockSummaryby-lot-global" render={(props) => <StockSummarybyLotGlobal {...props} />} />
                <Route path="/digital-icr-global" render={(props) => <DigitalICRGlobal {...props} />} />
                <Route path="/mcwc-digital-icr" render={(props) => <MCWCDigitalICRGlobal {...props} />} />
                <Route path="/receive-report-global" render={(props) => <ReceiveReportGlobal {...props} />} />
                <Route path="/mcwc-receiver-report" render={(props) => <MCWCReceiveReport {...props} />} />
                <Route path="/issue-details-by-item-global" render={(props) => <IssueDetailsByItemGlobal {...props} />} />
                <Route path="/issue-details-by-facility-global" render={(props) => <IssueDetailsByFacilityGlobal {...props} />} />
                <Route path="/stock-expiry-By-lot-global" render={(props) => <StockExpiryByLotGlobal {...props} />} />
                <Route path="/mcwc-stock-expiry-By-lot" render={(props) => <StockExpiryByLotMCWC {...props} />} />
                <Route path="/adjustments-report-global" render={(props) => <AdjustmentsReportGlobal {...props} />} />
                <Route path="/mcwc-adjustments-report" render={(props) => <AdjustmentsReportmcwc {...props} />} />
                <Route path="/stock-transactions-by-lot-global" render={(props) => <StockTransactionsByLotGlobal {...props} />} />
                <Route path="/warehouse-issue-details-by-facility-global" render={(props) => <WarehouseIssueDetailsByFacilityGlobal {...props} />} />
                <Route path="/facility-elmis-activity-status" render={(props) => <FacilityeLMISActivityStatus {...props} />} />
                
                <Route path="/form7b-global" render={(props) => <Form7bGlobal {...props} />} />
                <Route path="/form7-global" render={(props) => <Form7Global {...props} />} />
                <Route path="/provisional-receiving-register-global" render={(props) => <ProvisionalReceivingRegisterGlobal {...props} />} />
                <Route path="/provisional-summary-report-global" render={(props) => <ProvisionalSummaryReportGlobal {...props} />} />
                
                <Route path="/sdp-distribution-summary-global" render={(props) => <SDPDistributionSummaryGlobal {...props} />} />
                <Route path="/asset-reports" render={(props) => <AssetView {...props} />} />
                <Route path="/mcwc-asset-report-global" render={(props) => <AssetViewGlobal {...props} />} />
                <Route path="/asset-register-report" render={(props) => <AssetReport {...props} />} />
                <Route path="/from_2-3_submission_report_global" render={(props) => <From23SubmissionReportGlobal {...props} />} />
                <Route path="/sdp_stockout_report_global" render={(props) => <SDPStockOutReportGlobal {...props} />} />
                <Route path="/sdp-ledger-report-global" render={(props) => <SDPLedgerReportGlobal {...props} />} />
                <Route path="/sdp-closing-summary-global" render={(props) => <SDPClosingSummaryGlobal {...props} />} />
                <Route path="/sdp_distribution_report_global" render={(props) => <SDPDistributionReportGlobal {...props} />} />
                <Route path="/mcwc-stock-summary" render={(props) => <MCWCStockSummary {...props} />} />
                <Route path="/mcwc-stock-summary-by-lot" render={(props) => <MCWCStockSummarybyLot {...props} />} />
                
                <Route path="/department-entry" render={(props) => <AssetDepartment {...props} />} />
                <Route path="/asset-maintenance-schedule" render={(props) => <AssetMaintenanceSchedule {...props} />} />
                <Route path="/asset-unit-entry" render={(props) => <AssetUnit {...props} />} />
                <Route path="/dds-kit-item-serial" render={(props) => <DDSkitItemSerial {...props} />} />
                <Route path="/country-entry" render={(props) => <CountryEntry {...props} />} />

                <Route path="/check-permission" render={(props) => <CheckPermission {...props} />} />
                <Route path="/" render={(props) => <Index {...props} />} />
              </Switch>
            </Suspense>
          </BrowserRouter>
        </QueryClientProvider>
        </UserContextProvider>

        <DarkFooter />

      {/*)}*/}

    </>
  );
};

export default MyApp;
