import React, { useState, useEffect } from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import * as api from "../../../actions/api";
import AssetstatusFormData from "./AssetRegisterFormData.js";

const AddAssetRegister = (props) => {
  const FacilityId = localStorage.getItem("FacilityId");
  //get DispensingLanguage
  const DispensingLanguage =JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem('LangCode');
  const menukey = "asset-register";
  const UserInfo = sessionStorage.getItem("User_info")? JSON.parse(sessionStorage.getItem("User_info")): 0;  
  const UserName = UserInfo==0?'': UserInfo[0].id;


  const [formData, setFormData] = useState({
    DeptId:"",
    SectionId:"",
    AssetLocation:"",
    ItemNo:"",
    InitialPrice:"",
    BrandName:"",
    UsefulLife:"",
    ModelNo:"",
    MenufacturerSNo:"",
    DateRecvInStore:null,
    InstallationDate:null,
    SupplyDate:null,
    MStatusId:"",
    LastDateWarranty:null,
    SupCode:"",
    ManufacturerId:"",
    CountryId:"",
    ProcuringId:"",
    FundingSourceId:"",
    DepRate:"",
    SalvageVal:"",
    MScheduleId:"",
    Remarks:"",
    FacilityId:"",
    StatusDate:null,
    StatusRemarks:"",
    ItemSl:"",
  });

  const [errorObject, setErrorObject] = useState({});
  const { id } = useParams();
  const queryClient = useQueryClient();

  useQuery(
    ["AssetRegisterServer", id],
    () =>
      api.getAssetregisterListItem(id).then((res) => {
        setFormData(res.data);
      }),
    {
      enabled: Boolean(id),
    }
  );

  const { mutate } = useMutation(api.saveassetRegister, {
    onSuccess: (data) => {
      if (data.status == 200) {

        props.openNoticeModal({
          isOpen: true,
          msg: data.message,
          msgtype: data.success,
        });
		

        queryClient.getQueriesData("AssetRegisterServer");
        props.history.push("/asset-register");
       
    
        }else{
          props.openNoticeModal({
            isOpen: true,
            msg: data.message,
            msgtype: data.success,
          });
		
        }
    },
  });
  const handleAddContract = (getProductData) => {

    let fdata = { ...formData }; 
    let row = getProductData;
    
      fdata["ItemNo"] = row[0].ItemNo;
     fdata["ItemName"] = row[0].ItemName;

    setFormData(fdata); 
    setErrorObject({ ...errorObject, ItemNo: null });
  };
  
  const handleAddChangeStatus = (getChangeStatus) => {
    let fdata = { ...formData }; 
    let row = getChangeStatus;
      fdata["StatusDate"] = getChangeStatus.StatusDate;
     fdata["StatusRemarks"] = getChangeStatus.StatusRemarks;
    setFormData(fdata); 
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    let data = { ...formData };
    console.log("formData: ", formData);
    data[name] = value;

    setFormData(data);

    setErrorObject({ ...errorObject, [name]: null });
  };


    //====== Start Choosen Dropdown===========
    const handleChangeChoosenProps = (name, value) => {
      let data = { ...formData };
      data[name] = value;   
      setFormData(data); 
      setErrorObject({ ...errorObject, [name]: "" });
      api.dirtyModeEnable();
    }
    //====== End Choosen Dropdown===========
    


  const handleCheck = (e) => {
    const { name, checked } = e.target;
    setFormData({ ...formData, [name]: checked });
  };

  const handleReset = () => {
    setFormData({
      DeptId:"",
      SectionId:"",
      AssetLocation:"",
      ItemNo:"",
      InitialPrice:"",
      BrandName:"",
      UsefulLife:"",
      ModelNo:"",
      MenufacturerSNo:"",
      DateRecvInStore:null,
      InstallationDate:null,
      SupplyDate:null,
      MStatusId:"",
      LastDateWarranty:null,
      SupCode:"",
      ManufacturerId:"",
      CountryId:"",
      ProcuringId:"",
      FundingSourceId:"",
      DepRate:"",
      SalvageVal:"",
      MScheduleId:"",
      Remarks:"",
      FacilityId:"",
      StatusDate:null,
      StatusRemarks:"",
      ItemSl:"",
    });
  };

  const validateForm = (formData) => {
    let validateFields = ["InitialPrice","ItemName","ItemNo",
    "BrandName","UsefulLife","ModelNo","MStatusId","SupCode","ManufacturerId",
    "ProcuringId"];
    let errorData = {};
    let isValid = true;
    validateFields.map((field) => {
      if (!formData[field]) {
        errorData[field] = DispensingLanguage[lan][menukey]['field is Required !'];
        isValid = false;
      }
    });
    setErrorObject(errorData);
    return isValid;
  };


  const handleSubmit = async (e) => {
    console.log("from submit ", formData);
    if (validateForm(formData)) {
      let fDtat={...formData,"menukey": menukey,"lan":lan,"UserName":UserName,FacilityId:FacilityId}; 
      mutate(fDtat);
     // mutate(formData);
     
    }
  };

  const handleRDateChange = (date, field) => {
  
    setFormData({ ...formData, [field]: date });
    setErrorObject({ ...errorObject, [field]: null });
    api.dirtyModeEnable();
  };

  return (
    <>
      <AssetstatusFormData
        errorObject={errorObject}
        addProductForm={true}
        formData={formData}
        handleChange={handleChange}
        handleCheck={handleCheck}
        handleReset={handleReset}
        handleSubmit={handleSubmit}
        handleRDateChange={handleRDateChange}
        handleChangeChoosenProps={handleChangeChoosenProps}
        handleAddContract={handleAddContract}
        handleAddChangeStatus={handleAddChangeStatus}
        // ColorCode={ColorCode}
        // handleChangeColorAdd={handleChangeColorAdd}
        
        {...props}
      />
    </>
  );
};

export default AddAssetRegister;
