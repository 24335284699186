import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import * as Service from "../../../services/Service.js";
import {
  Typography,
  Grid,
  TextField,
  Button,
  FormControl,
  Card,
  CardHeader,
  CardContent,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";
import * as api from '../../../actions/api';
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment";
import ProductsModal from "services/AssetProductModal.js";
import AssetStatusModal from "services/AssetStatusModal.js";

// const menukey = "asset-status-entry";

const AssetRegisterFormData = ({
  errorObject,
  addProductForm,
  formData,
  handleChange,
  handleCheck,
  handleReset,
  handleSubmit,
  handleUpdate,
  // handleChangeColorAdd,
  ...props
}) => {
  //  console.log('formDataValue',formData);
  const DispensingLanguage = JSON.parse(localStorage.getItem("DispensingLanguage"));
  const lan = localStorage.getItem("LangCode");
  const classes = useStyles();
  // const theme = useTheme();
  const [value, setValue] = useState(0);
  const { t, i18n } = useTranslation();
  const menukey = "asset-register";

// console.log(FacilityId)
  const [open, setOpen] = useState(false);
  const [openAssetStatus, setOpenAssetStatus] = useState(false);


  const handleClose = (modalname) => {
    switch (modalname) {
      case "Products":
        setOpen(false);
        break;
      case "ProductsOpen":
        setOpen(true);
        break;

        case "AssetStatus":
          setOpenAssetStatus(false);
          break;
        case "AssetStatusOpen":
          setOpenAssetStatus(true);
          break;

      default:
        break;
    }
  };

  const addProduct = (getProductData) => {
    props.handleAddContract(getProductData);
    handleClose("Products");
    api.dirtyModeEnable();
  };

  const addAssetStatus = (getChangeStatus) => {
    console.log("getChangeStatus --- ",getChangeStatus);
    props.handleAddChangeStatus(getChangeStatus);
    handleClose("AssetStatus");
    /* api.dirtyModeEnable();  */
  };

  const SupplierList = JSON.parse(localStorage.getItem("SupplierListNational"));
  const ManufacturerList = JSON.parse(localStorage.getItem("ManufacturerListNational"));
  const AllCountryList = JSON.parse(localStorage.getItem("CountryList"));
  const AssetStatusList = JSON.parse(localStorage.getItem("AssetStatusList"));
  const AssetProcuringList = JSON.parse(localStorage.getItem("AssetProcuringList"));
  const FundingSourceList = JSON.parse(localStorage.getItem("FundingSourceList"));
  const DepartmentList = JSON.parse(localStorage.getItem("DepartmentList"));
  const MaintenanceScheduleList = JSON.parse(localStorage.getItem("MaintenanceScheduleList"));

  const [firstLoad, setFirstLoad] = useState(true);
  const [isLoading, setLoading] = useState(false);
  const [manyTableData, setManyTableData] = useState([]);



  let TempItemList = useState([]);

  const [chosenValues, setChosenValues] = useState({
    "DeptId": {"id": "","DeptName": ""},
    "SectionId": {"id": "","SectionName": ""},
    "SupCode": {"id": "","SupName": ""},
    "ManufacturerId": {"id": "","ManufacturerName": ""},
    "CountryId": {"id": "","CountryName": ""},
    "MStatusId": {"id": "","name": ""},
    "ProcuringId": {"id": "","ProcuringName": ""},
    "FundingSourceId": {"id": "","name": ""},
    "MScheduleId": {"id": "","MSchedule": ""},
  });

  //====== Start Choosen Dropdown===========



  useEffect(() => {
    let chosenValuesData = { ...chosenValues};

chosenValuesData['DeptId'] = DepartmentList[DepartmentList.findIndex(department_list => department_list.DeptId == formData.DeptId)]; 
chosenValuesData['SupCode'] = SupplierList[SupplierList.findIndex(supplier_list => supplier_list.SupCode == formData.SupCode)]; 
chosenValuesData['ManufacturerId'] = ManufacturerList[ManufacturerList.findIndex(Manufacture_list => Manufacture_list.ManufacturerId == formData.ManufacturerId)]; 
chosenValuesData['CountryId'] = AllCountryList[AllCountryList.findIndex(Country_list => Country_list.CountryId == formData.CountryId)]; 
chosenValuesData['MStatusId'] = AssetStatusList[AssetStatusList.findIndex(MStatus_list => MStatus_list.MStatusId == formData.MStatusId)]; 
chosenValuesData['ProcuringId'] = AssetProcuringList[AssetProcuringList.findIndex(Procuring_list => Procuring_list.ProcuringId == formData.ProcuringId)]; 
chosenValuesData['FundingSourceId'] = FundingSourceList[FundingSourceList.findIndex(FundingSource_list => FundingSource_list.FundingSourceId == formData.FundingSourceId)]; 
chosenValuesData['MScheduleId'] = MaintenanceScheduleList[MaintenanceScheduleList.findIndex(MSchedule_list => MSchedule_list.MScheduleId == formData.MScheduleId)]; 
chosenValuesData['SectionId'] = UnitList[UnitList.findIndex(Section_list => Section_list.SectionId == formData.SectionId)]; 

    setChosenValues(chosenValuesData); 
  
  }, [formData.DeptId,formData.SectionId,formData.SupCode,formData.ManufacturerId,formData.CountryId,formData.MStatusId,
  formData.ProcuringId,formData.FundingSourceId,formData.MScheduleId,formData.ItemNo,formData.SectionId]);
  




  const handleChangeChoosen = (name, valueobj, value) => {

    let chosenValuesData = { ...chosenValues};
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);

     if (name === "MStatusId") {
        if (!addProductForm && (value !== formData.MStatusId) && value !== "" && value !== null) {
            setOpenAssetStatus(true);
        }
      } 
  

    props.handleChangeChoosenProps(name, value);

  };

  // department section
  const [DeptId, setcurrDepartment] = useState(0);
  const [UnitList, setUnitListLocal] = useState(TempItemList);
  // console.log(UnitList);
  const [currUnitId, setcurrUnitId] = useState(0);


  const handleChangeDepartment = (name, valueobj, value) => {
    //var value = valueobj.DeptId;
    let chosenValuesData = { ...chosenValues };
    chosenValuesData[name] = valueobj;
    setChosenValues(chosenValuesData);
    setFirstLoad(true);
    setLoading(false);
    setcurrDepartment(value);

    props.handleChangeChoosenProps(name, value);
  };

   const handleChangeChoosenUnit = (name, valueobj, value) => {
    let chosenValuesData = { ...chosenValues};
    chosenValuesData[name] = valueobj;   
    formData["SectionId"] = "";
  
    setChosenValues(chosenValuesData);
    setFirstLoad(true);
    setLoading(false);
    setcurrUnitId(value);
  
    props.handleChangeChoosenProps(name, value);
  
  };

  React.useEffect(() => { 
    if(formData.DeptId !==''){
      setcurrDepartment(formData.DeptId);
    }
  }, [formData.DeptId]);
  
  
  React.useEffect(() => {
    cItemList();
  }, [DeptId]);



  const cItemList = () => {

    let ItemListParam = { action: "getUnitItemList", DeptId: DeptId, menukey: "" }

    Service.default
      .postApi("source/combo_generic.php", ItemListParam)
      .then((res) => {
      setUnitListLocal(res.datalist);
      })
      .catch((err) => { });

  }


  //====== End Choosen Dropdown===========

    const backtolist = () => {
      let master_dirty_message_class = document
        .getElementById("master_dirty_message")
        .getAttribute("class");
      let idx = master_dirty_message_class.indexOf("dnone");
      if (idx === -1) {
        // start Alert
        swal({
          title: t(DispensingLanguage[lan][menukey]["Are you sure?"]),
          text: t(
            DispensingLanguage[lan][menukey][
              "You have unsaved changes which will be lost. Continue?"
            ]
          ),
          icon: "warning",
          buttons: {
            confirm: {
              text: t(DispensingLanguage[lan][menukey]["Yes"]),
              value: true,
              visible: true,
              className: "",
              closeModal: true,
            },
            cancel: {
              text: t(DispensingLanguage[lan][menukey]["No"]),
              value: null,
              visible: true,
              className: "",
              closeModal: true,
            },
          },
        }).then((willAction) => {
          if (willAction) {
            props.history.push("/asset-register");
          }
        });
        // End Alert
      } else {
        props.history.push("/asset-register");
      }
    };





  return (
    <div className={classes.productPageTitle}>
    <div className="sw_makeStyles_tableContainer">
      <div className="d-flex justify-product mb-3">
        <Grid item xs={12} sm={12}>
          <div className="sw_page_heading">
            <div className="sw_heading_title">
              {" "}
              {
                DispensingLanguage[lan][menukey][
                  "Asset Management Register- Create/Edit"
                ]
              }{" "}
            </div>
          </div>
          <div className="float-right sw_btn_control">
            {addProductForm ? (
              <Grid item xs={12} className="mt-4 text-center tex_margin">
                <Button
                  disabled={props.bStockUpdate}
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => handleReset()}>
                  {t(DispensingLanguage[lan][menukey]["Reset"])}
                </Button>
                <Button
                  disabled={props.bStockUpdate}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => handleSubmit()}>
                  {t(DispensingLanguage[lan][menukey]["Save"])}
                </Button>
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/asset-register")}>
                  
                  {t(DispensingLanguage[lan][menukey]["Back to List"])}
                </Button>
              </Grid>
            ) : (
              <Grid item xs={12} className="mt-2 text-center">
                <Button
                  disabled={props.bStockUpdate}
                  className="mr-2"
                  variant="contained"
                  color="primary"
                  onClick={() => handleUpdate()}>
                  {t(DispensingLanguage[lan][menukey]["update"])}
                </Button>
                <Button
                  className="mr-2"
                  variant="contained"
                  type="reset"
                  onClick={() => props.history.push("/asset-register")}>
                   {/* onClick={() => backtolist()}> */}
                  {t(DispensingLanguage[lan][menukey]["Back to List"])}
                </Button>
              </Grid>
            )}
          </div>

        </Grid>
      </div>

      {/* New row */}
      <Grid container spacing={10}>
        {/* New row */}
        <Grid item xs={12} sm={12}>
          <Card className="sw_card">
            <CardHeader
              title={
                DispensingLanguage[lan][menukey]["Asset Management Register"]
              }
            />
            <CardContent>
              <Grid container spacing={10}>
                <Grid item xs={4} sm={12}>
                  <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                      <Grid container spacing={2}>
                        <Grid item xs={6} sm={6}>
                        <TextField
                          disabled={true}
                          error={errorObject.ItemNo}
                          helperText={errorObject.ItemNo}
                          id="ItemNo"
                          name="ItemNo"
                          label={DispensingLanguage[lan][menukey]["Equipment Name"]}
                          value={formData.ItemName}
                          fullWidth
                          autoComplete="family-name"
                          required
                        />
                        </Grid>
                    
                        <Grid item xs={6} sm={6}>
                          <Button 
                            variant="contained"
                            color="primary"
                            disabled={formData.ItemSl > 0}
                            style={{ fontSize: '12px',marginTop: '20px' }}
                            onClick={() => handleClose("ProductsOpen")}
                            >
                            Select Asset
                          </Button>
                        </Grid>
                      </Grid>

                      <ProductsModal
                          handleClose={handleClose}
                          open={open}
                          addProduct={addProduct}
                          {...props}
                        />
                      <AssetStatusModal
                          handleClose={handleClose}
                          open={openAssetStatus}
                          addAssetStatus={addAssetStatus}
                          {...props}
                        />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          id="MStatusId"
                          options={AssetStatusList}
                          onChange={(event, valueobj) =>
                            handleChangeChoosen(
                              "MStatusId",
                              valueobj,
                              valueobj ? valueobj.MStatusId : ""
                            )
                          }
                          getOptionLabel={(option) => option.name}
                         value={chosenValues["MStatusId"]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.MStatusId}
                              label={
                                DispensingLanguage[lan][menukey]["Present Status"]
                              }
                              variant="standard"
                              error={errorObject.MStatusId}
                              helperText={errorObject.MStatusId}
                              id="MStatusId"
                              name="MStatusId"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <TextField
                        error={errorObject.BrandName}
                        helperText={errorObject.BrandName}
                        id="BrandName"
                        name="BrandName"
                        label={
                          DispensingLanguage[lan][menukey]["Asset Brand"]
                        }
                        value={formData.BrandName}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        disabled={props.bStockUpdate}
                        required
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        error={errorObject.ModelNo}
                        helperText={errorObject.ModelNo}
                        id="ModelNo"
                        name="ModelNo"
                        label={
                          DispensingLanguage[lan][menukey]["Asset Model"]
                        }
                        value={formData.ModelNo}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        disabled={props.bStockUpdate}
                        required
                      />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <TextField
                        error={errorObject.InitialPrice}
                        helperText={errorObject.InitialPrice}
                        id="InitialPrice"
                        name="InitialPrice"
                        type="number"
                        label={
                          DispensingLanguage[lan][menukey]["Purchase Value"]
                        }
                        value={formData.InitialPrice}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        disabled={props.bStockUpdate}
                        required
                      />
                    </Grid>

                    <Grid item xs={6} sm={3}>
                      <TextField
                        error={errorObject.UsefulLife}
                        helperText={errorObject.UsefulLife}
                        id="UsefulLife"
                        name="UsefulLife"
                        type="number"
                        label={
                          DispensingLanguage[lan][menukey]["Life Span (Approx Year)"]
                        }
                        value={formData.UsefulLife}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        disabled={props.bStockUpdate}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          id="SupCode"
                          options={SupplierList}
                          onChange={(event, valueobj) =>
                            handleChangeChoosen(
                              "SupCode",
                              valueobj,
                              valueobj ? valueobj.SupCode : ""
                            )
                          }
                          getOptionLabel={(option) => option.SupName}
                          value={chosenValues["SupCode"]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.SupName}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.SupCode}
                              label={
                                DispensingLanguage[lan][menukey]["Supplier"]
                              }
                              variant="standard"
                              error={errorObject.SupCode}
                              helperText={errorObject.SupCode}
                              id="SupCode"
                              name="SupCode"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>


                    
                    <Grid item xs={12} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          id="ManufacturerId"
                          options={ManufacturerList}
                          onChange={(event, valueobj) =>
                            handleChangeChoosen(
                              "ManufacturerId",
                              valueobj,
                              valueobj ? valueobj.ManufacturerId : ""
                            )
                          }
                          getOptionLabel={(option) => option.ManufacturerName}
                          value={chosenValues["ManufacturerId"]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.ManufacturerName}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.ManufacturerId}
                              label={
                                DispensingLanguage[lan][menukey]["Manufacturer"]
                              }
                              variant="standard"
                              error={errorObject.ManufacturerId}
                              helperText={errorObject.ManufacturerId}
                              id="ManufacturerId"
                              name="ManufacturerId"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                    

                    <Grid item xs={12} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                         
                          id="ProcuringId"
                          options={AssetProcuringList}
                          onChange={(event, valueobj) =>
                            handleChangeChoosen(
                              "ProcuringId",
                              valueobj,
                              valueobj ? valueobj.ProcuringId : ""
                            )
                          }
                          getOptionLabel={(option) => option.ProcuringName}
                          value={chosenValues["ProcuringId"]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.ProcuringName}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.ProcuringId}
                              label={
                                DispensingLanguage[lan][menukey]["Procuring Entity"]
                              }
                              variant="standard"
                              error={errorObject.ProcuringId}
                              helperText={errorObject.ProcuringId}
                              id="ProcuringId"
                              name="ProcuringId"
                              fullWidth
                              required
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <FormControl className={classes.fullWidth}>
                      <Autocomplete
                            autoHighlight
                            id="CountryId"
                            disabled={props.permissionType}
                            options={AllCountryList}
                            onChange={(event, valueobj) =>handleChangeChoosen("CountryId",valueobj,valueobj ? valueobj.CountryId : "")}
                            getOptionLabel={(option) => option.CountryName}
                            value={chosenValues["CountryId"]}
                            renderOption={(option) => (
                              <Typography className="sw_fontSize">
                                {option.CountryName}
                              </Typography>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                value={formData.CountryId}
                                label={
                                  DispensingLanguage[lan][menukey]["Country of Origin"]
                                }
                                variant="standard"
                                error={errorObject.CountryId}
                                helperText={errorObject.CountryId}
                                id="CountryId"
                                name="CountryId"
                                fullWidth
                              />
                            )}
                          />
                      </FormControl>
                    </Grid>
                        
                    <Grid item xs={12} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          id="DeptId"
                          options={DepartmentList}
                          onChange={(event, valueobj) => handleChangeDepartment('DeptId', valueobj, valueobj ? valueobj.DeptId : "")}
                          getOptionLabel={(option) => option.DeptName}
                           value={chosenValues["DeptId"]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.DeptName}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.DeptId}
                              // value={chosenValues["DeptId"]}
                              label={
                                DispensingLanguage[lan][menukey]["Department"]
                              }
                              variant="standard"
                              error={errorObject.DeptId}
                              helperText={errorObject.DeptId}
                              id="DeptId"
                              name="DeptId"
                              fullWidth
                              
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                    <FormControl className={classes.fullWidth}>
                      <Autocomplete
                        autoHighlight
                        id="SectionId"
                        options={UnitList}
                        onChange={(event, valueobj) => handleChangeChoosenUnit('SectionId', valueobj, valueobj ? valueobj.SectionId : "")}
                        getOptionLabel={(option) => option.SectionName}
                        value={chosenValues["SectionId"]}
                        renderOption={(option) => (
                          <Typography className="sw_fontSize">
                            {option.SectionName}
                          </Typography>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            value={formData.SectionId}
                            label={DispensingLanguage[lan][menukey]["Unit"]}
                            variant="standard"
                            error={errorObject.SectionId}
                            helperText={errorObject.SectionId}
                            id="SectionId"
                            name="SectionId"
                            fullWidth
                            
                          />
                        )}
                      />
                    </FormControl>

                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <TextField
                        error={errorObject.AssetLocation}
                        helperText={errorObject.AssetLocation}
                        id="AssetLocation"
                        name="AssetLocation"
                        label={
                          DispensingLanguage[lan][menukey][
                            "Location Indication"
                          ]
                        }
                        value={formData.AssetLocation}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        disabled={props.bStockUpdate}
                      />
                    </Grid>
                

                    <Grid item xs={12} sm={3}>
                      <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          id="FundingSourceId"
                          options={FundingSourceList}
                          onChange={(event, valueobj) =>
                            handleChangeChoosen(
                              "FundingSourceId",
                              valueobj,
                              valueobj ? valueobj.FundingSourceId : ""
                            )
                          }
                          getOptionLabel={(option) => option.name}
                          value={chosenValues["FundingSourceId"]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.name}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.FundingSourceId}
                              label={
                                DispensingLanguage[lan][menukey][
                                  "Source of Fund"
                                ]
                              }
                              variant="standard"
                              error={errorObject.FundingSourceId}
                              helperText={errorObject.FundingSourceId}
                              id="FundingSourceId"
                              name="FundingSourceId"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>
                   
					          
                    
                   
                    <Grid item xs={6} sm={3}>
                      <TextField
                        error={errorObject.MenufacturerSNo}
                        helperText={errorObject.MenufacturerSNo}
                        id="MenufacturerSNo"
                        name="MenufacturerSNo"
                        label={
                          DispensingLanguage[lan][menukey][
                            "Product Serial No"
                          ]
                        }
                        value={formData.MenufacturerSNo}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        disabled={props.bStockUpdate}
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          //disabled={bStockUpdate}
                          // required
                          disabled={props.bStockUpdate}
                          error={errorObject.DateRecvInStore}
                          helperText={errorObject.DateRecvInStore}
                          className={classes.fullWidth}
                          //disableToolbar
                          variant="inline"
                          id="DateRecvInStore"
                          label={
                            DispensingLanguage[lan][menukey][
                              "Date of Receipt by Health Facility"
                            ]
                          }
                          autoOk={true}
                          name="DateRecvInStore"
                          fullWidth
                          showTodayButton={true}
                          disableFuture={true}
                          value={
                            formData.DateRecvInStore || null
                          }
                          format="dd/MM/yyyy"
                          onChange={(e) =>
                            props.handleRDateChange(e, "DateRecvInStore")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          error={errorObject.InstallationDate}
                          helperText={errorObject.InstallationDate}
                          className={classes.fullWidth}
                          //disableToolbar

                          disabled={props.bStockUpdate}
                          variant="inline"
                          id="InstallationDate"
                          label={
                            DispensingLanguage[lan][menukey][
                              "Date of Installation"
                            ]
                          }
                          autoOk={true}
                          name="InstallationDate"
                          fullWidth
                          showTodayButton={true}
                          disableFuture={true}
                          value={
                            formData.InstallationDate || null
                            //formData.dateofjoin || props.selectedDate
                          }
                          format="dd/MM/yyyy"
                          onChange={(e) =>
                            props.handleRDateChange(e, "InstallationDate")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          error={errorObject.SupplyDate}
                          helperText={errorObject.SupplyDate}
                          className={classes.fullWidth}
                          //disableToolbar

                          disabled={props.bStockUpdate}
                          variant="inline"
                          id="SupplyDate"
                          label={
                            DispensingLanguage[lan][menukey]["Date of Commissioning"]
                          }
                          autoOk={true}
                          name="SupplyDate"
                          fullWidth
                          showTodayButton={true}
                          disableFuture={true}
                          value={
                            formData.SupplyDate || null
                            //formData.dateofjoin || props.selectedDate
                          }
                          format="dd/MM/yyyy"
                          onChange={(e) =>
                            props.handleRDateChange(e, "SupplyDate")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>
                    
                    <Grid item xs={12} sm={3}>
                      <MuiPickersUtilsProvider
                        libInstance={moment}
                        utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          /* error={errorObject.LastDateWarranty}
                          helperText={errorObject.LastDateWarranty}  */
                          className={classes.fullWidth}
                          //disableToolbar

                          disabled={props.bStockUpdate}
                          variant="inline"
                          id="LastDateWarranty"
                          label={
                            DispensingLanguage[lan][menukey]["Last Date of Warranty"]
                          }
                          autoOk={true}
                          name="LastDateWarranty"
                          fullWidth
                          showTodayButton={true}
                          // disableFuture={true}
                          value={
                            formData.LastDateWarranty || null
                            //formData.dateofjoin || props.selectedDate
                          }
                          format="dd/MM/yyyy"
                          onChange={(e) =>
                            props.handleRDateChange(e, "LastDateWarranty")
                          }
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          
                        />
                      </MuiPickersUtilsProvider>
                    </Grid>

                  


                    <Grid item xs={6} sm={3}>
                      <TextField
                        error={errorObject.DepRate}
                        helperText={errorObject.DepRate}
                        disabled={props.bStockUpdate}
                        id="DepRate"
                        name="DepRate"
                        type="number"
                        label={
                          DispensingLanguage[lan][menukey][
                            "Rate of Depreciation"
                          ]
                        }
                        value={formData.DepRate}
                        fullWidth
                        autoComplete="family-name"
                        inputProps={{
                          maxLength: 17,
                        }}
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <TextField
                        error={errorObject.SalvageVal}
                        helperText={errorObject.SalvageVal}
                        disabled={props.bStockUpdate}
                        id="SalvageVal"
                        name="SalvageVal"
                        type="number"
                        label={
                          DispensingLanguage[lan][menukey]["Salvage Value"]
                        }
                        value={formData.SalvageVal}
                        fullWidth
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3}>
                    <FormControl className={classes.fullWidth}>
                        <Autocomplete
                          autoHighlight
                          id="MScheduleId"
                          options={MaintenanceScheduleList}
                          onChange={(event, valueobj) =>
                            handleChangeChoosen(
                              "MScheduleId",
                              valueobj,
                              valueobj ? valueobj.MScheduleId : ""
                            )
                          }
                          getOptionLabel={(option) => option.MSchedule}
                          value={chosenValues["MScheduleId"]}
                          renderOption={(option) => (
                            <Typography className="sw_fontSize">
                              {option.MSchedule}
                            </Typography>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              value={formData.MScheduleId}
                              label={
                                DispensingLanguage[lan][menukey]["Maintenance Schedule"]
                              }
                              variant="standard"
                              error={errorObject.MScheduleId}
                              helperText={errorObject.MScheduleId}
                              id="MScheduleId"
                              name="MScheduleId"
                              fullWidth
                            />
                          )}
                        />
                      </FormControl>

                    </Grid>
                    <Grid item xs={6} sm={6}>
                      <TextField
                        error={errorObject.Remarks}
                        helperText={errorObject.Remarks}
                        id="Remarks"
                        name="Remarks"
                        label={DispensingLanguage[lan][menukey]["Remarks"]}
                        value={formData.Remarks}
                        fullWidth
                        multiline
                        rows={2}
                        autoComplete="family-name"
                        onChange={(e) => handleChange(e)}
                        disabled={props.bStockUpdate}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        {/* Action buttons */}
      </Grid>
    </div>
  </div>
  );
};

export default AssetRegisterFormData;

const useStyles = makeStyles({
  productPageTitle: {
    marginTop: "60px",
    color: "white",
    padding: "10px",
  },
  tableContainer: {
    backgroundColor: "whitesmoke",
    borderRadius: "10px",
    padding: "2rem",
    color: "black",
  },
  fullWidth: {
    width: "100%",
  },
});
